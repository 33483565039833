import "../styles/globals.css";
import type { AppProps } from "next/app";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import { Piwik } from "../src/components/Piwik";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <NextUIProvider theme={theme}>
      <Component {...pageProps} />
      <Piwik />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
    </NextUIProvider>
  );
}

export default MyApp;

const theme = createTheme({
  type: "light", // it could be "light" or "dark"
  theme: {
    colors: {
      backgroundContrast: "#ffffff",
      background: "#ffe2af",
      gradient:
        "linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)",
    },
    space: {},
    fonts: {},
  },
});
